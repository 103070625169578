<template>
  <Async :loading="isLoading">
    <v-alert v-if="isCantEdit" dense border="left" type="warning">
      無法變更
      <strong>超級管理員</strong>
      的權限
    </v-alert>
    <v-card outlined class="w100p">
      <v-card-title class="Secondary100">
        <v-row class="overflow-x-auto">
          <v-col cols="2" class="flex-center">選單名稱</v-col>
          <v-col cols="2">權限</v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="py-4">
        <v-row class="overflow-x-auto">
          <v-col cols="2">01 首頁</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.home.read"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="Secondary200">
        <v-row class="overflow-x-auto">
          <v-col cols="2">02 商戶管理</v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">02-01 商戶列表</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.merchant_list.read"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="新增"
                :readonly="isCantEdit"
                v-model="grants.merchant_list.add"
                dense
              />
              <v-checkbox
                label="編輯"
                :readonly="isCantEdit"
                v-model="grants.merchant_list.edit"
                dense
              />
              <v-checkbox
                label="刪除"
                :readonly="isCantEdit"
                v-model="grants.merchant_list.remove"
                dense
              />
            </div>
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              label="帳戶狀態"
              :readonly="isCantEdit"
              v-model="grants.merchant_list.status"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              label="儲值功能"
              :readonly="isCantEdit"
              v-model="grants.merchant_list.collect_order"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              label="調整資金"
              :readonly="isCantEdit"
              v-model="grants.merchant_list.capital_management"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              label="密碼管理"
              :readonly="isCantEdit"
              v-model="grants.merchant_list.password_management"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="代收風控"
                :readonly="isCantEdit"
                v-model="grants.merchant_list.collect_rule"
                dense
              />
              <v-checkbox
                label="代付風控"
                :readonly="isCantEdit"
                v-model="grants.merchant_list.pay_rule"
                dense
              />
              <v-checkbox
                label="費率設定"
                :readonly="isCantEdit"
                v-model="grants.merchant_list.fee_percent_setting"
                dense
              />
              <v-checkbox
                label="提單"
                :readonly="isCantEdit"
                v-model="grants.merchant_list.place_order"
                dense
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">02-02 商戶類別</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.merchant_type_list.read"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="新增"
                :readonly="isCantEdit"
                v-model="grants.merchant_type_list.add"
                dense
              />
              <v-checkbox
                label="編輯"
                :readonly="isCantEdit"
                v-model="grants.merchant_type_list.edit"
                dense
              />
              <v-checkbox
                label="刪除"
                :readonly="isCantEdit"
                v-model="grants.merchant_type_list.remove"
                dense
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="Secondary200">
        <v-row class="overflow-x-auto">
          <v-col cols="2">03 訂單管理</v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">03-01 代收訂單</v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="瀏覽"
                :readonly="isCantEdit"
                v-model="grants.collect_order_list.read"
                dense
              />
              <v-checkbox
                label="檢視所有帳戶資訊"
                :readonly="isCantEdit"
                v-model="grants.collect_order_list.read_all_cards"
                dense
              />
            </div>
          </v-col>
          <v-col cols="auto"></v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="詳細"
                :readonly="isCantEdit"
                v-model="grants.collect_order_list.details"
                dense
              />
              <v-checkbox
                label="補單"
                :readonly="isCantEdit"
                v-model="grants.collect_order_list.replenishment"
                dense
              />
              <v-checkbox
                label="審核補單"
                :readonly="isCantEdit"
                v-model="grants.collect_order_list.approve_replenishment"
                dense
              />
              <v-checkbox
                label="補發通知"
                :readonly="isCantEdit"
                v-model="grants.collect_order_list.notify"
                dense
              />
              <v-checkbox
                label="檢視手續費"
                :readonly="isCantEdit"
                v-model="grants.collect_order_list.view_fee"
                dense
              />
              <v-checkbox
                label="檢視用戶入帳"
                :readonly="isCantEdit"
                v-model="grants.collect_order_list.view_credit_amt"
                dense
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">03-02 未認領訂單</v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="瀏覽"
                :readonly="isCantEdit"
                v-model="grants.not_process_collect_order_list.read"
                dense
              />
              <v-checkbox
                label="檢視所有帳戶資訊"
                :readonly="isCantEdit"
                v-model="grants.not_process_collect_order_list.read_all_cards"
                dense
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">03-03 代收黑名單</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.black_list_collect_order_list.read"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="新增"
                :readonly="isCantEdit"
                v-model="grants.black_list_collect_order_list.add"
                dense
              />
              <v-checkbox
                label="編輯"
                :readonly="isCantEdit"
                v-model="grants.black_list_collect_order_list.edit"
                dense
              />
              <v-checkbox
                label="刪除"
                :readonly="isCantEdit"
                v-model="grants.black_list_collect_order_list.remove"
                dense
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="Secondary200">
        <v-row class="overflow-x-auto">
          <v-col cols="2">04 代付管理</v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">04-01 商戶代付列表</v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="瀏覽"
                :readonly="isCantEdit"
                v-model="grants.pay_order_list.read"
                dense
              />
              <v-checkbox
                label="檢視所有帳戶資訊"
                :readonly="isCantEdit"
                v-model="grants.pay_order_list.read_all_cards"
                dense
              />
            </div>
          </v-col>
          <v-col cols="auto"></v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="顯示詳細資訊"
                :readonly="isCantEdit"
                v-model="grants.pay_order_list.show"
                dense
              />
              <v-checkbox
                label="撿單"
                :readonly="isCantEdit"
                v-model="grants.pay_order_list.assign"
                dense
              />
              <v-checkbox
                label="釋出"
                :readonly="isCantEdit"
                v-model="grants.pay_order_list.release"
                dense
              />
              <v-checkbox
                label="編輯"
                :readonly="isCantEdit"
                v-model="grants.pay_order_list.edit"
                dense
              />
              <v-checkbox
                label="轉單(代釋出)"
                :readonly="isCantEdit"
                v-model="grants.pay_order_list.transfer"
                dense
              />
              <v-checkbox
                label="設定"
                :readonly="isCantEdit"
                v-model="grants.pay_order_list.setting"
                dense
              />
              <v-checkbox
                label="檢視手續費"
                :readonly="isCantEdit"
                v-model="grants.pay_order_list.view_fee"
                dense
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">04-02 API代付列表</v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="瀏覽"
                :readonly="isCantEdit"
                v-model="grants.api_pay_order_list.read"
                dense
              />
              <v-checkbox
                label="檢視所有帳戶資訊"
                :readonly="isCantEdit"
                v-model="grants.api_pay_order_list.read_all_cards"
                dense
              />
            </div>
          </v-col>
          <v-col cols="auto"></v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="顯示詳細資訊"
                :readonly="isCantEdit"
                v-model="grants.api_pay_order_list.show"
                dense
              />
              <v-checkbox
                label="撿單"
                :readonly="isCantEdit"
                v-model="grants.api_pay_order_list.assign"
                dense
              />
              <v-checkbox
                label="釋出"
                :readonly="isCantEdit"
                v-model="grants.api_pay_order_list.release"
                dense
              />
              <v-checkbox
                label="編輯"
                :readonly="isCantEdit"
                v-model="grants.api_pay_order_list.edit"
                dense
              />
              <v-checkbox
                label="轉單(代釋出)"
                :readonly="isCantEdit"
                v-model="grants.api_pay_order_list.transfer"
                dense
              />
              <v-checkbox
                label="設定"
                :readonly="isCantEdit"
                v-model="grants.api_pay_order_list.setting"
                dense
              />
              <v-checkbox
                label="補發通知"
                :readonly="isCantEdit"
                v-model="grants.api_pay_order_list.notify"
                dense
              />
              <v-checkbox
                label="檢視手續費"
                :readonly="isCantEdit"
                v-model="grants.api_pay_order_list.view_fee"
                dense
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">04-03 代付黑名單</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.black_list_pay_order_list.read"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="新增"
                :readonly="isCantEdit"
                v-model="grants.black_list_pay_order_list.add"
                dense
              />
              <v-checkbox
                label="編輯"
                :readonly="isCantEdit"
                v-model="grants.black_list_pay_order_list.edit"
                dense
              />
              <v-checkbox
                label="刪除"
                :readonly="isCantEdit"
                v-model="grants.black_list_pay_order_list.remove"
                dense
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="Secondary200">
        <v-row class="overflow-x-auto">
          <v-col cols="2">05 資金管理</v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">05-01 帳變紀錄</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.capital_log_list.read"
              dense
            />
          </v-col>
          <v-col cols="auto"></v-col>
          <v-col cols="auto">
            <v-checkbox
              label="匯出"
              :readonly="isCantEdit"
              v-model="grants.capital_log_list.export"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">05-02 儲值排行</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.collect_orders_leaderboard.read"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">05-03 凍結紀錄</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.frozen_log_list.read"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="新增"
                :readonly="isCantEdit"
                v-model="grants.frozen_log_list.add"
                dense
              />
              <v-checkbox
                label="編輯"
                :readonly="isCantEdit"
                v-model="grants.frozen_log_list.edit"
                dense
              />
            </div>
          </v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="解凍"
                :readonly="isCantEdit"
                v-model="grants.frozen_log_list.unfreeze"
                dense
              />
              <v-checkbox
                label="解凍"
                :readonly="isCantEdit"
                v-model="grants.frozen_log_list.edit_freeze_note"
                dense
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="Secondary200">
        <v-row class="overflow-x-auto">
          <v-col cols="2">06 風控管理</v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">06-01 代收風控</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.collect_rule.read"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              label="編輯"
              :readonly="isCantEdit"
              v-model="grants.collect_rule.edit"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">06-02 代付風控</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.pay_rule.read"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              label="編輯"
              :readonly="isCantEdit"
              v-model="grants.pay_rule.edit"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="Secondary200">
        <v-row class="overflow-x-auto">
          <v-col cols="2">07 通道管理</v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">07-01 交易通道設定</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.collect_channel_settings.read"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="新增"
                :readonly="isCantEdit"
                v-model="grants.collect_channel_settings.add"
                dense
              />
              <v-checkbox
                label="編輯"
                :readonly="isCantEdit"
                v-model="grants.collect_channel_settings.edit"
                dense
              />
              <v-checkbox
                label="刪除"
                :readonly="isCantEdit"
                v-model="grants.collect_channel_settings.remove"
                dense
              />
            </div>
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              label="通道狀態"
              :readonly="isCantEdit"
              v-model="grants.collect_channel_settings.status"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              label="撞單風控"
              :readonly="isCantEdit"
              v-model="grants.collect_channel_settings.avoid_duplicate"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="風控設定"
                :readonly="isCantEdit"
                v-model="grants.collect_channel_settings.rule_setting"
                dense
              />
              <v-checkbox
                label="費率設定"
                :readonly="isCantEdit"
                v-model="grants.collect_channel_settings.fee_percent_setting"
                dense
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">07-02 子帳戶列表</v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="瀏覽"
                :readonly="isCantEdit"
                v-model="grants.cards_list.read"
                dense
              />
              <v-checkbox
                label="檢視所有帳戶資訊"
                :readonly="isCantEdit"
                v-model="grants.cards_list.read_all_cards"
                dense
              />
            </div>
          </v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="新增"
                :readonly="isCantEdit"
                v-model="grants.cards_list.add"
                dense
              />
              <v-checkbox
                label="編輯"
                :readonly="isCantEdit"
                v-model="grants.cards_list.edit"
                dense
              />
              <v-checkbox
                label="刪除"
                :readonly="isCantEdit"
                v-model="grants.cards_list.remove"
                dense
              />
            </div>
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              label="帳戶狀態"
              :readonly="isCantEdit"
              v-model="grants.cards_list.status"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              label="自動上分開關"
              :readonly="isCantEdit"
              v-model="grants.cards_list.enabled_auto_collect"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              label="風控設定"
              :readonly="isCantEdit"
              v-model="grants.cards_list.rule_setting"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">07-03 轉單通道設定</v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="Secondary200">
        <v-row class="overflow-x-auto">
          <v-col cols="2">08 數據報表</v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">08-01 交易統計報表</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.platform_order_reports.read"
              dense
            />
          </v-col>
          <v-col cols="auto"></v-col>
          <v-col cols="auto">
            <v-checkbox
              label="匯出"
              :readonly="isCantEdit"
              v-model="grants.platform_order_reports.export"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">08-02 商戶帳務報表</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.merchant_accounting_reports.read"
              dense
            />
          </v-col>
          <v-col cols="auto"></v-col>
          <v-col cols="auto">
            <v-checkbox
              label="匯出"
              :readonly="isCantEdit"
              v-model="grants.merchant_accounting_reports.export"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">08-03 代理帳務報表</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.agent_accounting_reports.read"
              dense
            />
          </v-col>
          <v-col cols="auto"></v-col>
          <v-col cols="auto">
            <v-checkbox
              label="匯出"
              :readonly="isCantEdit"
              v-model="grants.agent_accounting_reports.export"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">08-04 通道帳務統計</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.channel_accounting_reports.read"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="Secondary200">
        <v-row class="overflow-x-auto">
          <v-col cols="2">09 系統日誌</v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">09-01 簡訊紀錄</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.sms_list.read"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <!-- <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">09-02 登入日誌</v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="瀏覽全部"
                :readonly="isCantEdit"
                v-model="grants.signin_log_list.read_all"
                dense
              />
              <v-checkbox
                label="瀏覽個人"
                :readonly="isCantEdit"
                v-model="grants.signin_log_list.read_self"
                dense
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text> -->

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">09-02 操作紀錄</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.operation_log_list.read"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <!-- <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">09-04 日誌備份</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.backup_logs.read"
              dense
            />
          </v-col>
          <v-col cols="auto"></v-col>
          <v-col cols="auto">
            <v-checkbox
              label="下載"
              :readonly="isCantEdit"
              v-model="grants.backup_logs.download"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text> -->

      <v-card-text class="Secondary200">
        <v-row class="overflow-x-auto">
          <v-col cols="2">10 系統權限</v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">10-01 管理員名單</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.user_list.read"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="新增"
                :readonly="isCantEdit"
                v-model="grants.user_list.add"
                dense
              />
              <v-checkbox
                label="編輯"
                :readonly="isCantEdit"
                v-model="grants.user_list.edit"
                dense
              />
              <v-checkbox
                label="刪除"
                :readonly="isCantEdit"
                v-model="grants.user_list.remove"
                dense
              />
            </div>
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              label="谷歌驗證碼"
              :readonly="isCantEdit"
              v-model="grants.user_list.google_2fa_secret"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">10-02 角色管理</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.role_list.read"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="新增"
                :readonly="isCantEdit"
                v-model="grants.role_list.add"
                dense
              />
              <v-checkbox
                label="編輯"
                :readonly="isCantEdit"
                v-model="grants.role_list.edit"
                dense
              />
              <v-checkbox
                label="刪除"
                :readonly="isCantEdit"
                v-model="grants.role_list.remove"
                dense
              />
            </div>
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              label="詳細"
              :readonly="isCantEdit"
              v-model="grants.role_list.details"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              label="名單"
              :readonly="isCantEdit"
              v-model="grants.role_list.list"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <!-- <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">10-03 選單設定</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.menu_settings.read"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <div>
              <v-checkbox
                label="新增"
                :readonly="isCantEdit"
                v-model="grants.menu_settings.add"
                dense
              />
              <v-checkbox
                label="編輯"
                :readonly="isCantEdit"
                v-model="grants.menu_settings.edit"
                dense
              />
              <v-checkbox
                label="刪除"
                :readonly="isCantEdit"
                v-model="grants.menu_settings.remove"
                dense
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text> -->

      <v-card-text class="Secondary200">
        <v-row class="overflow-x-auto">
          <v-col cols="2">11 系統管理</v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">11-01 網站設定</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.platform_settings.read"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              label="編輯"
              :readonly="isCantEdit"
              v-model="grants.platform_settings.edit"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">11-02 密碼修改</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.password_settings.read"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              label="編輯"
              :readonly="isCantEdit"
              v-model="grants.password_settings.edit"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <!-- <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">11-03 清理暫存</v-col>

          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.clear_cache.read"
              dense
            />
          </v-col>
          <v-col cols="auto"></v-col>
          <v-col cols="auto">
            <v-checkbox
              label="清理暫存"
              :readonly="isCantEdit"
              v-model="grants.clear_cache.clear_cache"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text> -->

      <!-- <v-card-text>
        <v-row class="overflow-x-auto">
          <v-col cols="2">11-04 系統版本</v-col>
          <v-col cols="auto">
            <v-checkbox
              label="瀏覽"
              :readonly="isCantEdit"
              v-model="grants.system_version.read"
              dense
            />
          </v-col>
          <v-col cols="auto"></v-col>
          <v-col cols="auto">
            <v-checkbox
              label="線上更新"
              :readonly="isCantEdit"
              v-model="grants.system_version.upgrade"
              dense
            />
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              label="聯絡客服"
              :readonly="isCantEdit"
              v-model="grants.system_version.contact"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text> -->

      <v-card-actions class="flex-center">
        <v-btn
          rounded
          :loading="isBtnLoading"
          color="primary"
          depressed
          width="320"
          @click="submit"
        >
          儲存
        </v-btn>
      </v-card-actions>
    </v-card>
  </Async>
</template>

<script>
import {
  getMenuActionPermissions,
  editMenuActionPermissions
} from '@/api/roles';
import grantsSchema from '../../../grants';

export default {
  props: {
    role: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      isLoading: true,
      isBtnLoading: false,
      grants: JSON.parse(JSON.stringify(grantsSchema))
    };
  },

  computed: {
    isCantEdit() {
      return this.role.slug === 'admin';
    }
  },

  async created() {
    const { grants, error } = await getMenuActionPermissions(
      this.$route.query.roleId
    );
    if (error) return;

    this.grants = { ...this.grants, ...grants };

    this.$store.commit('app/cleanTempBreadcrumbs');
    this.$store.commit('app/addTempBreadcrumbs', {
      disabled: undefined,
      exact: true,
      name: this.role.name,
      path: `${this.$route.path}?roleId=${this.role.id}`,
      text: this.role.name
    });

    this.isLoading = false;
  },

  beforeDestroy() {
    this.$store.commit('app/cleanTempBreadcrumbs');
  },

  methods: {
    async submit() {
      this.isBtnLoading = true;

      const { success, error } = await editMenuActionPermissions({
        roleId: this.role.id,
        grants: this.grants
      });

      if (error || !success) {
        this.$toast.error('儲存失敗');
        console.error(error);
      } else {
        this.$store.commit('auth/setGrants', this.grants);
        this.$toast('儲存成功');
        this.$router.go(-1);
      }

      this.isBtnLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-card__text {
    border-top: 1px $Secondary300 solid;
    border-bottom: 1px $Secondary300 solid;
  }

  .row {
    flex-wrap: nowrap;
  }

  .col {
    border-left: 1px $Secondary300 solid;
    border-right: 1px $Secondary300 solid;

    display: flex;
    align-items: center;
    min-width: 120px;
  }
  .col ~ .col {
    border-left: none;
    border-right: 1px $Secondary300 solid;
  }
}
</style>