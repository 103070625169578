<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card min-height="500">
      <v-card-title class="pos-r">
        {{ localData.mode === 'new' ? '新增' : '編輯' }}
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <SizeBox height="48" />
        <v-form ref="form">
          <v-text-field
            rounded
            v-model="localData.name"
            label="角色"
            placeholder="輸入角色名稱"
            :rules="[$rules.required]"
            outlined
          ></v-text-field>

          <v-text-field
            rounded
            v-model="localData.description"
            label="說明"
            :rules="[$rules.required]"
            outlined
          ></v-text-field>

          <v-switch
            label="帳戶狀態"
            v-model="localData.status"
            inset
          ></v-switch>

          <v-text-field
            rounded
            v-model="googleVerifyCode"
            label="谷哥驗證"
            placeholder="請輸入驗證碼"
            :rules="[$rules.required]"
            :error-messages="googleVerifyError"
            outlined
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          rounded
          color="Secondary100"
          depressed
          large
          :disabled="loading"
          @click="close"
        >
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          dark
          depressed
          large
          :loading="loading"
          @click="submit"
        >
          確認
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { v4 as uuid } from 'uuid';

import { addRole, editRole } from '@/api/roles';
import schema from './schema';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * mode: String, // new, edit
         */
      })
    }
  },

  data() {
    return {
      localData: {},

      loading: false,
      googleVerifyCode: '',
      googleVerifyError: null
    };
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      this.$set(this, 'localData', { ...schema, ...this.passData });
      this.$refs.form && this.$refs.form.resetValidation();
    },
    reset() {
      this.$set(this, 'localData', {});
      this.$refs.form && this.$refs.form.resetValidation();
    },
    close() {
      this.$emit('close');
    },
    async submit() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;
      const isNew = this.localData.mode === 'new';
      const firedApi = isNew ? addRole : editRole;
      const passdata = { ...this.localData };

      if (isNew) {
        passdata.slug = uuid();
        delete passdata.id;
      }

      const Role = await firedApi(passdata);
      if (!Role.error) {
        this.$emit('save');
        this.$toast('保存成功');
        this.close();
      } else {
        this.$toast.error('保存失敗');
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
