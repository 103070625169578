<template>
  <!-- eslint-disable  -->
  <div>
    <v-card flat class="w100p">
      <!--  -->
    </v-card>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{
        isEditingRole ? editingRole.name : $store.getters['app/getRouteTitle']
      }}
      <v-spacer></v-spacer>
      <v-btn
        rounded
        v-if="!isEditingRole"
        color="primary"
        depressed
        @click="openForm('Edit', { mode: 'new' })"
        :disabled="!$getGrantValue('role_list.add')"
      >
        新增
      </v-btn>
    </div>

    <v-card outlined v-if="!isEditingRole">
      <DataTable
        :list="result.list"
        :total="result.total"
        :fields="headers"
        :replace="['status', 'actions']"
        :loading="isLoading"
        @get-list="fetchData"
      >
        <template #item.status="{ item }">
          <div class="flex-center">
            <v-switch
              color="Primary"
              :input-value="item.status"
              @click="changeStatus(item)"
              :disabled="item.isStatusChanging"
              class="mr-n4"
              readonly
              inset
            ></v-switch>
          </div>
        </template>
        <template #item.actions="{ item }">
          <div class="flex-center">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  color="Secondary100"
                  rounded
                  :small="$vuetify.breakpoint.smAndDown"
                >
                  操作
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list rounded>
                <v-list-item
                  v-for="(key, i) in item.actions"
                  :key="`key:${item.id} ${i}`"
                  @click="
                    key.action && handleAction((vm) => key.action(vm, item))
                  "
                  :to="key.to ? key.to(item) : null"
                  :disabled="key.grant ? !$getGrantValue(key.grant) : false"
                >
                  <v-icon v-if="!!key.icon" size="18" class="mr-1">
                    {{ key.icon }}
                  </v-icon>
                  {{ key.label }}
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </DataTable>
    </v-card>

    <AuthorityForm v-else :role="editingRole" />

    <EditForm
      :value="showingForm === 'Edit'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    >
      <v-list-item
        v-if="$getGrantValue('role_list.add')"
        @click="openForm('Edit', { mode: 'new' })"
      >
        <v-icon>mdi-plus</v-icon>
        <span class="text-no-wrap">新增</span>
      </v-list-item>
    </FunctionFab>
  </div>
</template>

<script>
import { getRoles, editRoleStatus } from '@/api/roles';

import EditForm from '@/components/system-management/roles/EditForm.vue';
import AuthorityForm from '@/components/system-management/roles/AuthorityForm.vue';

const actions = [
  {
    label: '編輯',
    icon: 'mdi-pencil-outline',
    grant: 'role_list.edit',
    action: (vm, v) => {
      vm.openForm('Edit', v);
    }
  },
  {
    label: '名單',
    icon: 'mdi-file-document-outline',
    grant: 'role_list.list',
    to: (v) => ({
      name: '管理員名單',
      query: { roleId: v.id, name: v.name }
    }),
    action: (vm, v) => {
      //
    }
  },
  {
    label: '權限',
    icon: 'mdi-shield-account-outline',
    grant: 'role_list.details',
    action: (vm, v) => {
      vm.pushToAuthManagement(v);
    }
  }
];

export default {
  components: {
    EditForm,
    AuthorityForm
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      ...this.$clone(this.$commonSchema),
      headers: [
        { text: '角色', value: 'name', align: 'center' },
        { text: '說明', value: 'description', align: 'center' },
        { text: '狀態', value: 'status', align: 'center' },
        { text: '操作', value: 'actions', align: 'center' }
      ]
    };
  },

  computed: {
    isEditingRole() {
      return !!this.$route.query.roleId;
    },
    editingRole() {
      const roleId = this.$route.query.roleId;
      if (roleId) {
        const role = this.result.list.find(({ id }) => roleId === id);
        return role || {};
      } else {
        return {};
      }
    }
  },

  methods: {
    openForm(name, data = {}, mode = '') {
      this.showingForm = name;

      this.$set(this, 'passData', {
        ...data
      });
    },
    handleAction(fn) {
      fn(this);
    },
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;
      const Roles = await getRoles({
        ...this.filter,
        ...condition
      });

      if (!Roles.error) {
        this.result.total = Roles.total;
        this.result.page = Roles.page;
        this.$set(
          this.result,
          'list',
          Roles.items.map((el) => ({
            ...el,
            isStatusChanging: false,
            actions: actions,
            ...condition
          }))
        );
      }

      this.isLoading = false;
    },

    pushToAuthManagement(role) {
      this.$router.push({ query: { roleId: role.id } });
    },

    async changeStatus(item) {
      item.isStatusChanging = true;
      const { error } = await editRoleStatus({
        id: item.id,
        status: !item.status
      });

      if (error) {
        return;
      } else {
        item.status = !item.status;
      }

      item.isStatusChanging = false;

      return error;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
